import { type FC, forwardRef, type ReactElement } from 'react'

import { ClearAdornment, TextField, type TextFieldProps } from 'ui'

import InputHelperText from './InputHelperText'
import InputTitle from './InputTitle'

interface Props
    extends Pick<
        TextFieldProps,
        | 'className'
        | 'label'
        | 'required'
        | 'InputProps'
        | 'focused'
        | 'onChange'
        | 'value'
        | 'onClick'
        | 'inputProps'
        | 'disabled'
        | 'name'
    > {
    error?: { message?: string } | string | boolean
    onClear?: () => void
    alwaysClearable?: boolean
    helperText?: string | ReactElement
    ref?: any
}

const TextInputBase: FC<Props> = forwardRef<any, any>((props: Props, ref) => {
    const {
        label,
        helperText,
        error,
        onClear,
        InputProps,
        required,
        value: valueProp,
        alwaysClearable,
        inputProps,
        disabled,
        ...rest
    } = props

    const value = inputProps?.value || InputProps?.value || valueProp

    const errorMessage = typeof error === 'object' ? error.message : error
    const isClearable = !disabled && ((onClear && !required) || alwaysClearable)
    const displayClearAdornment = isClearable && Boolean(value)

    const endArornment =
        isClearable || InputProps?.endAdornment ? (
            <>
                {displayClearAdornment && (
                    <ClearAdornment
                        onClick={(event) => {
                            event.stopPropagation()
                            onClear?.()
                        }}
                    />
                )}
                {InputProps?.endAdornment}
            </>
        ) : null

    return (
        <TextField
            {...rest}
            disabled={disabled}
            inputProps={inputProps}
            InputProps={{
                ...InputProps,
                endAdornment: endArornment,
            }}
            value={value}
            ref={ref}
            error={Boolean(error)}
            helperText={
                <InputHelperText
                    error={errorMessage}
                    helperText={helperText}
                />
            }
            label={
                <InputTitle
                    label={label}
                    isRequired={required}
                />
            }
        />
    )
})

export default TextInputBase
