import { type FC, useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import {
    TextInput,
    costMaskParams,
    TotalFormField,
    type DialogSelectorProps,
    inputQtyPositiveMaskParams,
} from 'components'
import { useRecordContext, requiredValidation, validateMaxLength } from 'core'
import { type PartWithInventoryModel } from 'resources/inventory'
import { PartInput } from 'resources/parts'
import { poResource, PoTypeKeys, type PurchaseOrderModel } from 'resources/purchaseOrders'
import { Typography, BoxContainer } from 'ui'

const qtyAndCostValidator = [validateMaxLength(10, 'Invalid value'), requiredValidation]

interface LineItemsFormProps {
    po: PurchaseOrderModel
}

const LineItemsForm: FC<LineItemsFormProps> = ({ po }) => {
    const { setValue, getValues } = useFormContext()

    const isCredit = po.type === 'CREDIT'
    const isClosed = po.status === 'CLOSED'

    return (
        <>
            <TypeInput po={po} />
            <LineItemPartInput
                po={po}
                onSelectedChange={({ selected }) => {
                    setValue(
                        'price',
                        (selected as PartWithInventoryModel)?.inventoryData?.acquisitionCost || 0,
                        {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                        },
                    )
                }}
                disabled={isClosed || Boolean(getValues('id'))}
            />

            <TextInput
                source="quantity"
                label={isCredit ? 'Quantity Returned' : 'Quantity'}
                {...inputQtyPositiveMaskParams}
                validate={qtyAndCostValidator}
                disabled={isClosed}
            />

            <TextInput
                source="price"
                label="Part Cost"
                {...costMaskParams}
                validate={qtyAndCostValidator}
                disabled={isClosed}
            />

            <BoxContainer justifyContent="space-between">
                <Typography color="text.disabled">Total</Typography>
                <Typography
                    color="text.primary"
                    variant="subtitle2"
                >
                    <TotalFormField
                        inputOne="quantity"
                        inputTwo="price"
                    />
                </Typography>
            </BoxContainer>
        </>
    )
}

export default LineItemsForm

// data needed for the serializer
const TypeInput: FC<{ po: PurchaseOrderModel }> = ({ po }) => {
    const record = useRecordContext()
    const { register } = useFormContext()

    useEffect(() => {
        // can't set value if not a Promise, it gets cleared
        Promise.resolve().then(() => {
            register('poType', {
                value: po.type,
            })
        })
    }, [record])

    return null
}

export const LineItemPartInput: FC<{ po: PurchaseOrderModel } & Partial<DialogSelectorProps>> = ({
    po,
    ...props
}) => {
    return (
        <PartInput<PartWithInventoryModel>
            contextType={poResource.name}
            contextId={po.id}
            inputTitle="Part"
            type="inventory"
            {...props}
            createValues={{
                shop: po.shop,
            }}
            allowInventoryCreate={po.type === PoTypeKeys.STANDARD}
        />
    )
}
