import { type FC, type ReactElement } from 'react'

import ClearIcon from '@mui/icons-material/Clear'
import { FieldTitle, InputHelperText, ResettableTextField } from 'react-admin'

import { type ExtendRecordType, type DataRecord } from 'appTypes'
import Icons from 'assets/icons'
import { extendRecord } from 'core'
import { alpha } from 'lib'
import { IconButton, InputAdornment, Outlined, type TextFieldProps } from 'ui'

import { useDialogSelectorContext } from './DialogSelectorContext'
import { type DialogSelectorUniversalParam } from './types'

export interface DialogSelectorTogglerProps<OptionType extends DataRecord = any> {
    renderToggler?: (params: DialogSelectorUniversalParam<OptionType>) => ReactElement | false
    renderSelectedToggler?: (params: DialogSelectorUniversalParam<OptionType>) => ReactElement
    defaultSelectorValueSource?: ExtendRecordType<
        DialogSelectorUniversalParam<OptionType>,
        string | number
    >
    defaultSelectorProps?: TextFieldProps
    resettable?: boolean
}

const DialogSelectorToggler: FC<DialogSelectorTogglerProps> = ({
    renderSelectedToggler,
    renderToggler,
    defaultSelectorValueSource,
    defaultSelectorProps,
    resettable,
}) => {
    const data = useDialogSelectorContext()

    const { selected, disabled, source, value, input, handleOpen, required, onSelect } = data

    if (renderToggler) {
        const toggler = renderToggler(data)
        if (toggler !== false) {
            return toggler
        }
    }

    if (selected && renderSelectedToggler) {
        return (
            <Outlined
                title={defaultSelectorProps?.label}
                required={required}
            >
                {renderSelectedToggler(data)}
            </Outlined>
        )
    }

    const label = defaultSelectorProps?.label
    const helperText = defaultSelectorProps?.helperText

    return (
        // @ts-ignore
        <ResettableTextField
            id={input.id}
            {...defaultSelectorProps}
            name={source}
            label={
                label !== '' && label !== false ? (
                    <FieldTitle
                        label={label as any}
                        source={source}
                        isRequired={required}
                    />
                ) : null
            }
            error={
                (input.fieldState.isTouched || input.formState.isSubmitted) &&
                input.fieldState.invalid
            }
            helperText={
                <InputHelperText
                    touched={input.fieldState.isTouched || input.formState.isSubmitted}
                    error={input.fieldState.error?.message}
                    helperText={helperText as any}
                />
            }
            sx={{
                input: {
                    cursor: disabled ? null : 'pointer !important',
                    textOverflow: 'ellipsis',
                },
                label: {
                    // TODO: find better way to overflow before end adornment
                    maxWidth: selected ? null : 'calc(100% - var(--label-space, 60px))!important',
                },
            }}
            variant="outlined"
            size="medium"
            focused={false}
            onClick={handleOpen}
            disabled={disabled}
            InputProps={
                disabled
                    ? null
                    : {
                          endAdornment: (
                              <>
                                  {(resettable ?? !required) && value && (
                                      <InputAdornment position="end">
                                          <IconButton
                                              onClick={(e) => {
                                                  e.stopPropagation()
                                                  onSelect(null)
                                              }}
                                              disabled={disabled}
                                          >
                                              <ClearIcon />
                                          </IconButton>
                                      </InputAdornment>
                                  )}
                                  <Icons.ArrowForward
                                      onClick={handleOpen}
                                      sx={{
                                          color: (theme) => alpha(theme.palette.grey[900], 0.54),
                                          cursor: 'pointer !important',
                                      }}
                                  />
                              </>
                          ),
                      }
            }
            onChange={() => {}}
            inputProps={{
                onFocus: (e) => e.target.blur(),
                readOnly: true,
                value:
                    (defaultSelectorValueSource
                        ? extendRecord(data, defaultSelectorValueSource)
                        : value) || '',
            }}
        />
    )
}

export default DialogSelectorToggler
