import { type DataRecord } from 'appTypes'
import { NoResultsCard } from 'components'
import { type WorkOrderFormProps } from 'resources/workOrders'

import { type WorkOrderEditDrawerTogglerProps } from '../components/WorkOrderDrawerToggler'

import WorkOrderSimpleListNoResultsCreateAction, {
    type WorkOrderSimpleListNoResultsCreateActionProps,
} from './WorkOrderSimpleListNoResultsCreateAction'

export interface WorkOrderSimpleListNoResultsProps<RecordType extends DataRecord>
    extends WorkOrderSimpleListNoResultsCreateActionProps<RecordType> {
    removeCreate?: boolean
    disabledFields?: WorkOrderFormProps['disabledFields']
    createFormConfig?: WorkOrderFormProps['createFormConfig']
    values?: WorkOrderEditDrawerTogglerProps['values']
}

const WorkOrderSimpleListNoResults = <RecordType extends DataRecord>({
    removeCreate,
    ...props
}: WorkOrderSimpleListNoResultsProps<RecordType>) => {
    return (
        <NoResultsCard
            direction="row"
            height="180px"
            title="No Active Work Orders"
            imageSrc={(images) => images.listEmpty}
            imageProps={{
                width: '107px',
                height: '92px',
                mb: '20px',
                mr: {
                    xs: '16px',
                    sm: '20px',
                },
            }}
            action={removeCreate ? null : <WorkOrderSimpleListNoResultsCreateAction {...props} />}
        />
    )
}

export default WorkOrderSimpleListNoResults
